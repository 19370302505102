import React from 'react'
import { Link } from 'gatsby'
import { Headline } from '@liquid-design/liquid-design-react'
import LayoutLegal from 'layouts/LayoutLegal/LayoutLegal'

export default function Imprint() {
  return (
    <LayoutLegal>
      <div className="stack mb-20">
        <header className="pb-10">
          <Headline type="H1" className="pb-4">
            Imprint
          </Headline>
        </header>
        <Headline type="H2">Address</Headline>
        <address className="not-italic pb-10">
          EMD Digital Inc.
          <br />
          400 Summit Drive
          <br />
          Burlington, MA 01803
          <br />
          USA
          <br />
          <br />
          Phone:{' '}
          <a
            className="font-extrabold text-vibrant-cyan hover:text-vibrant-cyan-dark hover:underline"
            href="tel:+13147805662"
          >
            +1 314 780 5662
          </a>
          <br />
          <br />
          <a
            className="font-extrabold text-vibrant-cyan hover:text-vibrant-cyan-dark hover:underline"
            href="mailto:info@knoragene.com"
          >
            info@knoragene.com
          </a>
          <br />
          <Link
            className="font-extrabold text-vibrant-cyan hover:text-vibrant-cyan-dark hover:underline"
            to="/"
          >
            www.knoragene.com
          </Link>
        </address>
        <Headline type="H2" id="changes">
          Details
        </Headline>
        <p>
          Legal form: Massachusetts corporation
          <br />
          Massachusetts
          <br />
          ID Number: 001344149
          <br />
          Registered Agent: Corporation Service Company, 251 Little Falls Drive,
          Wilmington, DE 19808 USA
          <br />
          Employer Identification Number (EIN): 83-1784758
        </p>
        <p>
          <strong>Board of Directors (Managers):</strong>
          <br />
          James Kugler
          <br />
          Monica Elliott
        </p>
        <address className="not-italic">
          <strong>Responsible for the content:</strong>
          <br />
          Michael Vande Vrede
          <br />
          EMD Digital Inc.
          <br />
          400 Summit Dr.
          <br />
          Burlington, MA 01803
          <br />
          USA
        </address>
        <p>
          You may also have a look at our{' '}
          <Link
            className="font-extrabold text-vibrant-cyan hover:text-vibrant-cyan-dark hover:underline"
            to="/privacy/"
          >
            privacy statement
          </Link>{' '}
          to see that we take data protection and confidentiality very seriously
          and on our site do not make further use of your social media
          contributions.
        </p>
      </div>
    </LayoutLegal>
  )
}
